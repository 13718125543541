<template>
  <div class="inline-block w-min">
    <div
      class="bg-[#F9F9F9] border-[1px] border-gray rounded-[3px] flex items-center h-11"
    >
      <button
        id="btn-decrement"
        type="button"
        class="p-[10px]"
        @click="decrement"
      >
        <IconsProductMinus class="w-3 h-3" />
      </button>
      <input
        id="input-quantity"
        v-model="value"
        v-mask="mask"
        class="text-[16px] text-center bg-[#F9F9F9] px-2 border-none !ring-0"
        :style="`width: ${length + 2}ch`"
      />

      <button
        id="btn-increment"
        type="button"
        class="p-[10px]"
        @click="increment"
      >
        <IconsProductPlus class="w-3 h-3" />
      </button>
    </div>
  </div>
</template>

<script setup>
import * as vueTheMask from 'vue-the-mask'
// import toNumber from 'lodash/toNumber'
const vMask = (el, binding) => {
  if (!binding.value) {
    return
  }
  el.value = `${Number.parseInt(el.value)}`
  vueTheMask.mask(el, binding)
}
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 1
  },
  max: {
    type: Number,
    default: null
  },
  min: {
    type: Number,
    default: null
  }
})
const emits = defineEmits(['update:modelValue'])
const value = computed({
  get() {
    return props.modelValue || 1
  },
  set(v) {
    emits('update:modelValue', +v)
  }
})
const increment = () => {
  value.value += 1
}
const decrement = () => {
  if (value.value > 1) {
    value.value -= 1
  }
}
const length = computed(() => `${value.value}`.length)
const mask = new Array(20).fill(null).map((_, k) => '#' + '#'.repeat(k))
</script>
